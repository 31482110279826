$theme: #40B04E !default;

a {
    color: $theme;
}

.bg-main {
    background-color: $theme;
}

.border-main {
    border-color: $theme !important;
}

.badge-main {
    color: #fff;
    background-color: $theme
}

.card-body {
    overflow-y: unset;
}

.btn-main {
    background-color: $theme;
    color: #fff;
}

.sidebar {
    background: #fff;
    border-right: 1px solid $theme;

    .nav-item {
        .nav-link {
            color: #000;

            i {
                color: $theme
            }
        }
    }
}

.page-item {
    .page-link {
        background-color: #fff;
        border: 1px solid #dddfeb;
        color: $theme;
        display: block;
        line-height: 1.25;
        margin-left: -1px;
        padding: .5rem .75rem;
        position: relative;
    }

    &.active .page-link {
        background-color: $theme;
        border-color: $theme;
    }
}

.crud_list_head {
    height: 48px;
    display: flex;
    flex-direction: row;
    padding: 0;

    .row {
        width: 100%;
        margin: 0;
        padding: 0 14px;
    }
}

.sidebar-fixed {
    height: 100vh;
    width: 220px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    z-index: 1050;
    background-color: #fff;
    padding: 1.5rem;
    padding-top: 0;
}

.sidebar-fixed .list-group .active {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.sidebar-fixed .logo-wrapper {
    padding: 2.5rem;
}

@media (min-width: 991px) {
    #admin {
        .navbar, .page-footer, main {
            padding-left: 220px;
        }
    }
}

@media (max-width: 991px) {
    .sidebar-fixed {
        display: none;
    }
}

.md-tabs {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    border: 0;
    padding: .7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: -20px;
    z-index: 1;
    position: relative;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
}

.md-tabs .nav-link {
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    border: 0;
    color: #fff;
}

.md-tabs .nav-item.open .nav-link, .md-tabs .nav-link.active {
    background-color: rgba(0, 0, 0, .2);
    color: #fff;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
}

.badge_toggle_enable {
    cursor: pointer;
}

.btn.btn-supersm {
    padding: .1rem;
    font-size: 0.32rem;
    height: 1rem;
    width: 1rem;
}

.invalid-feedback {
    display: block;
}

.b-item {
    font-size: 12px;
    padding: 2px 3px;
    float: left;
    margin: 1.5px;
    border-radius: 5px;
    font-weight: 700;
    line-height: 12px;
}

.b-success {
    background-color: #00c851 !important;
    color: #fff;
}

.b-danger {
    background-color: #ff3547 !important;
    color: #fff;
}

.tabs-cyan {
    background-color: #00bcd4 !important;
}

.tab-content {
    padding: 1rem;
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.card-body {
    flex: 1 1 auto;
    padding: .8rem;
}

.admin-left-menu {
    font-size: 12px;
}

.card-place {
    background-color: #fff8ca !important;
}

.classic-tabs .nav {
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    border-radius: .3rem .3rem 0 0;
}

.classic-tabs .nav.tabs-cyan li a.active {
    border-color: #ffeb3b;
}

.classic-tabs .nav li a:not(.active) {
    margin-bottom: 3px;
}

.classic-tabs .nav li a.active {
    color: #fff;
    border-bottom: 3px solid;
}

.classic-tabs .nav li a {
    display: block;
    padding: 10px 24px;
    font-size: 13px;
    color: rgba(255, 255, 255, .7);
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
}

a {
    color: $theme;
}

.bg-contrast {
    background: #e4e6f0;
}

.table th, .table td {
    vertical-align: middle !important;
}

div.dataTables_paginate {
    float: left;
    margin: 0 0 1rem 0 !important;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
    right: 1.3em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {

}

table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:after {
    color:$theme;
}

.entity_search_result {
    margin: 5px 0;

    .entity_search_item {
        margin-bottom: 1px;
        background: #e9e9e9;
        font-size: 12px;
        cursor: pointer;

        &:hover {
            background: #bcbcbc;
        }
    }
}

.model_entities {
    .model_entity {
        padding: 5px;
        margin: 0 0 10px;
        background: #ededed;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        align-items: center;

        label {
            margin: 0;
        }
    }
}
