// Global component styles

html {
    position: relative;
    min-height: 100%;
}

body {
    height: 100%;
}

a {
    &:focus {
        outline: none;
    }
}

// Main page wrapper
#wrapper {
    display: flex;

    #content-wrapper {
        background-color: $gray-100;
        width: 100%;
        overflow-x: hidden;

        #content {
            flex: 1 0 auto;
        }
    }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
}

// Scroll to top button
.scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: $white;
    background: fade-out($gray-800, .5);
    line-height: 46px;
    z-index: 99999;

    &:focus,
    &:hover {
        color: white;
    }

    &:hover {
        background: $gray-800;
    }

    i {
        font-weight: 800;
    }
}
