@import "./template/sb-admin-2";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "../vendor/image-uploader.min.scss";

@font-face {
    font-family: 'Muller';
    src: url('../../fonts/MullerThin.eot');
    src: url('../../fonts/MullerThin.eot?#iefix') format("embedded-opentype"),
    url('../../fonts/MullerThin.woff') format("woff"),
    url('../../fonts/MullerThin.woff2') format("woff2"),
    url('../../fonts/MullerThin.ttf') format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../../fonts/MullerLight.eot');
    src: url('../../fonts/MullerLight.eot?#iefix') format("embedded-opentype"),
    url('../../fonts/MullerLight.woff') format("woff"),
    url('../../fonts/MullerLight.woff2') format("woff2"),
    url('../../fonts/MullerLight.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../../fonts/MullerRegular.eot');
    src: url('../../fonts/MullerRegular.eot?#iefix') format("embedded-opentype"),
    url('../../fonts/MullerRegular.woff') format("woff"),
    url('../../fonts/MullerRegular.woff2') format("woff2"),
    url('../../fonts/MullerRegular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../../fonts/MullerMedium.eot');
    src: url('../../fonts/MullerMedium.eot?#iefix') format("embedded-opentype"),
    url('../../fonts/MullerMedium.woff') format("woff"),
    url('../../fonts/MullerMedium.woff2') format("woff2"),
    url('../../fonts/MullerMedium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../../fonts/MullerBold.eot');
    src: url('../../fonts/MullerBold.eot?#iefix') format("embedded-opentype"),
    url('../../fonts/MullerBold.woff') format("woff"),
    url('../../fonts/MullerBold.woff2') format("woff2"),
    url('../../fonts/MullerBold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.mce-notification {
    display: none !important;
}

@import "./parts/custom";


